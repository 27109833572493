@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.ql-editor {
  min-height: 150px !important;
  max-height: 250px !important;
  overflow: auto !important;
}
/* Pagination CSS */
.pagination {
  display: inline-flex;
  list-style: none;
  border-radius: 0.25rem;
  overflow: hidden;
}

.pagination li {
  margin: 0;
}

.pagination-button {
  padding: 0.5rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #4a5568;
  background-color: #edf2f7;
  border: 1px solid transparent;
  cursor: pointer;
  transition: all 0.3s ease;
}
/* 
.pagination-button:hover {
  background-color: #e2e8f0;
} */

.pagination-button:focus {
  outline: none;
  /* box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5); */
}

.pagination-button-disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.pagination-button-active {
  background-color: #4299e1;
  color: #ffffff;
}

.tag-input-container {
  display: inline-block;
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 5px;
}
.tags {
  display: flex;
  flex-wrap: wrap;
}
.tag {
  background-color: #F0F0F0;
  color: #333;
  padding: 5px 10px;
  border-radius: 15px;
  margin-right: 5px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}
.tag button {
  background: none;
  border: none;
  color: #888;
  cursor: pointer;
  margin-left: 5px;
}
.tag-input {
  border: none;
  outline: none;
  font-size: 16px;
  padding: 8px;
  border-radius: 5px;
  flex-grow: 1;
}


.blog_heading {
  line-height: 1em;
  font-size: 50px;
  letter-spacing: -1.2px;
  font-family: ITCAvantGardeBold;
  font-style: normal !important;
  margin: auto !important;
}
.boxeMainLoader{
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #00000050;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
}
.boxes {
  --size: 32px;
  --duration: 800ms;
  height: calc(var(--size) * 2);
  width: calc(var(--size) * 3);
  position: relative;
  transform-style: preserve-3d;
  transform-origin: 50% 50%;
  margin-top: calc(var(--size) * 1.5 * -1);
  transform: rotateX(60deg) rotateZ(45deg) rotateY(0deg) translateZ(0px);
}

.boxes .box {
  width: var(--size);
  height: var(--size);
  top: 0;
  left: 0;
  position: absolute;
  transform-style: preserve-3d;
}

.boxes .box:nth-child(1) {
  transform: translate(100%, 0);
  -webkit-animation: box1 var(--duration) linear infinite;
  animation: box1 var(--duration) linear infinite;
}

.boxes .box:nth-child(2) {
  transform: translate(0, 100%);
  -webkit-animation: box2 var(--duration) linear infinite;
  animation: box2 var(--duration) linear infinite;
}

.boxes .box:nth-child(3) {
  transform: translate(100%, 100%);
  -webkit-animation: box3 var(--duration) linear infinite;
  animation: box3 var(--duration) linear infinite;
}

.boxes .box:nth-child(4) {
  transform: translate(200%, 0);
  -webkit-animation: box4 var(--duration) linear infinite;
  animation: box4 var(--duration) linear infinite;
}

.boxes .box > div {
  --background: #5C8DF6;
  --top: auto;
  --right: auto;
  --bottom: auto;
  --left: auto;
  --translateZ: calc(var(--size) / 2);
  --rotateY: 0deg;
  --rotateX: 0deg;
  position: absolute;
  width: 100%;
  height: 100%;
  background: var(--background);
  top: var(--top);
  right: var(--right);
  bottom: var(--bottom);
  left: var(--left);
  transform: rotateY(var(--rotateY)) rotateX(var(--rotateX)) translateZ(var(--translateZ));
}

.boxes .box > div:nth-child(1) {
  --top: 0;
  --left: 0;
}

.boxes .box > div:nth-child(2) {
  --background: #145af2;
  --right: 0;
  --rotateY: 90deg;
}

.boxes .box > div:nth-child(3) {
  --background: #447cf5;
  --rotateX: -90deg;
}

.boxes .box > div:nth-child(4) {
  --background: #DBE3F4;
  --top: 0;
  --left: 0;
  --translateZ: calc(var(--size) * 3 * -1);
}

@-webkit-keyframes box1 {
  0%, 50% {
    transform: translate(100%, 0);
  }

  100% {
    transform: translate(200%, 0);
  }
}

@keyframes box1 {
  0%, 50% {
    transform: translate(100%, 0);
  }

  100% {
    transform: translate(200%, 0);
  }
}

@-webkit-keyframes box2 {
  0% {
    transform: translate(0, 100%);
  }

  50% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(100%, 0);
  }
}

@keyframes box2 {
  0% {
    transform: translate(0, 100%);
  }

  50% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(100%, 0);
  }
}

@-webkit-keyframes box3 {
  0%, 50% {
    transform: translate(100%, 100%);
  }

  100% {
    transform: translate(0, 100%);
  }
}

@keyframes box3 {
  0%, 50% {
    transform: translate(100%, 100%);
  }

  100% {
    transform: translate(0, 100%);
  }
}

@-webkit-keyframes box4 {
  0% {
    transform: translate(200%, 0);
  }

  50% {
    transform: translate(200%, 100%);
  }

  100% {
    transform: translate(100%, 100%);
  }
}

@keyframes box4 {
  0% {
    transform: translate(200%, 0);
  }

  50% {
    transform: translate(200%, 100%);
  }

  100% {
    transform: translate(100%, 100%);
  }
}


@font-face {
  font-family: ITCAvantGardeBold;
  src: url(../public/assets/font/ITCAvantGardeBold.ttf);
}
@font-face {
  font-family: ProximaNova-Bold;
  src: url(../public/assets/font/ProximaNova-Bold.ttf);
}
@font-face {
  font-family: ProximaNova-Extrablod;
  src: url(../public/assets/font/ProximaNova-Extrabld.ttf);
}
@font-face {
  font-family: ProximaNova-Regular;
  src: url(../public/assets/font/ProximaNova-Regular.ttf);
}
@font-face {
  font-family: ProximaNova-Semibold;
  src: url(../public/assets/font/ProximaNova-Semibold.ttf);
}
@font-face {
  font-family: ProximaNovaT-Thin;
  src: url(../public/assets/font/ProximaNovaT-Thin.ttf);
}

.web_text {
  font-weight: 500;
  color: #7780a1;
  font-size: 1.125rem;
  line-height: 30px;
  letter-spacing: 0.5px;
  font-family: ProximaNova-Regular;
}

.ql-syntax{
  background-color: black !important;
  border-radius: 10px !important;
  padding: 10px !important;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  color: white !important;
  overflow-x: auto !important;
}