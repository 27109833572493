.imgStyle {
  @apply bg-gradient-to-r from-blue-500 to-indigo-500 rounded-l-md;
  position: relative;
  overflow: hidden;
}

.cartoonimg {
  background: url('/public/space.jpg') no-repeat;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
}
